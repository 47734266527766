export const homeData = [
  {
    id: 1,
    subject: "Physics",
    name: "Physics",
    topic: "Optical Physics",
    status: `50`,
    img: require("../../../assets/img/pages/subjects/physics.png"),
    NoOfBooks: "3",
  },
  {
    id: 2,
    subject: "Chemistry",
    name: "Chemistry",
    topic: "Benzene, ketone",
    status: `10`,
    img: require("../../../assets/img/pages/subjects/chemistry.png"),
    NoOfBooks: "3",
  },
  {
    id: 3,
    subject: "Mathematics",
    name: "Mathematics",
    topic: "Calculas",
    status: `80`,
    img: require("../../../assets/img/pages/subjects/math.png"),
    NoOfBooks: "3",
  },
  {
    id: 4,
    subject: "English Grammer",
    name: "English Grammer",
    topic: "present tense, future tense",
    status: `95`,
    img: require("../../../assets/img/pages/subjects/english.png"),
    NoOfBooks: "3",
  },
  {
    id: 5,
    subject: "Hindi Grammer",
    name: "Hindi Grammer",
    topic: "Hindi Vyakaran",
    status: `50`,
    img: require("../../../assets/img/pages/subjects/hindi.png"),
    NoOfBooks: "3",
  },
  {
    id: 6,
    subject: "Social Science",
    name: "Social Science",
    topic: "Socerties, Pluto",
    status: `25`,
    img: require("../../../assets/img/pages/subjects/social-science.png"),
    NoOfBooks: "3",
  },
  {
    id: 7,
    subject: "History",
    name: "History",
    topic: "Modern History",
    status: `40`,
    img: require("../../../assets/img/pages/subjects/history.png"),
    NoOfBooks: "3",
  },
  {
    id: 8,
    subject: "Computer",
    name: "Computer",
    topic: "Computer Software",
    status: `31`,
    img: require("../../../assets/img/pages/subjects/computer.png"),
    NoOfBooks: "3",
  },
  {
    id: 9,
    subject: "General Knowledge",
    name: "General Knowledge",
    topic: "India's General Knowledge",
    status: `50`,
    img: require("../../../assets/img/pages/subjects/general.png"),
    NoOfBooks: "3",
  },

  {
    id: 0,
    subject: "Add New Class",
    name: "Add New Class",
    topic: "",
    status: `0`,
    img: require("../../../assets/img/pages/subjects/general.png"),
    NoOfBooks: "0",
  },
];
