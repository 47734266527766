const initialState = {
  mytaskss: [],
  routeParam: null,
  filteredMytaskss: []
}

const mytasks = (state = initialState, action) => {
  switch (action.type) {
    case "GET_TODOS":
      return { ...state, mytaskss: action.mytaskss, routeParam: action.routeParams }
    case "UPDATE_TODOS":
      let mytasks = action.mytasks
      let updatedState = { ...state.mytaskss, [mytasks.id]: { ...mytasks } }
      return { ...state, mytaskss: updatedState }

    case "COMPLETE_TASK":
      state.mytaskss.find(i => i.id === action.id).isCompleted = !action.value
      return { ...state }

    case "STAR_TASK":
      state.mytaskss.find(i => i.id === action.id).isStarred = !action.value
      return { ...state }

    case "IMPORTANT_TASK":
      state.mytaskss.find(i => i.id === action.id).isImportant = !action.value
      return { ...state }

    case "TRASH_TASK":
      state.mytaskss.find(i => i.id === action.id).isTrashed = true
      return { ...state }

    case "UPDATE_LABEL":
      let taskToUpdate = state.mytaskss.find(i => i.id === action.id).tags
      if (!taskToUpdate.includes(action.label)) taskToUpdate.push(action.label)
      else taskToUpdate.splice(taskToUpdate.indexOf(action.label), 1)
      return { ...state }

    case "UPDATE_TASK":
      let mytasksToUpdate = state.mytaskss.find(i => i.id === action.id)
      mytasksToUpdate.title = action.title
      mytasksToUpdate.desc = action.desc
      return { ...state }

    case "ADD_TASK":
      return { ...state }

    case "SEARCH_TASK":
      if (action.val.length) {
        let filteredMytaskss = state.mytaskss.filter(query => {
          return (
            query.title.toLowerCase().includes(action.val) ||
            query.desc.toLowerCase().includes(action.val)
          )
        })
        return { ...state, filteredMytaskss }
      } else {
        return { ...state }
      }

    case "CHANGE_FILTER":
      state.routeParam = action.filter
      return { ...state }

    default:
      return state
  }
}

export default mytasks
