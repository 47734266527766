// import mock from "./mock"
// import "./navbar/navbarSearch"
// import "./navbar/navbarBookmarkSearch"
// import "./auth/authentication"
// import "./apps/calendar"
// import "./apps/mytasks"

import mock from "./mock"
import "./apps/dataView"
import "../views/pages/my-class/HomeData"
import "./tables/aggrid"
import "../views/pages/my-class/HomeData"
import "./autoComplete/autoComplete"
import "./navbar/navbarSearch"
import "./navbar/navbarBookmarkSearch"
import "./auth/authentication"
import "./apps/email"
import "./apps/chat"
import "./apps/mytasks"
import "./apps/calendar"
import "./apps/userList"

mock.onAny().passThrough()
