import React from "react"
import * as Icon from "react-feather"
const navigationConfig = [
  {
    id: "home",
    title: "My Class",
    type: "item",
    icon: <Icon.Sidebar size={29} />,
    permissions: ["admin", "editor"],
    navLink: "/",
    parentOf: [
      "/chapter",
      "/content"
    ]
  },
  {
    id: "knowledgeBase",
    title: "My Notes",
    type: "item",
    icon: <Icon.FileText size={29} />,
    navLink: "/pages/my-notes",
    permissions: ["admin", "editor"],
    parentOf: [
      "/pages/my-notes/category/questions",
      "/pages/my-notes/category"
    ]
  },
  {
    id: "mytasks",
    title: "My Tasks",
    type: "item",
    icon: <Icon.CheckSquare size={29} />,
    permissions: ["admin", "editor"],
    navLink: "/mytasks/:filter",
    filterBase: "/mytasks/all"
  },
  {
    id: "Calendar",
    title: "Calendar",
    type: "item",
    icon: <Icon.Calendar size={29} />,
    permissions: ["admin", "editor"],
    navLink: "/Calendar"
  }, {
    id: "Statistics",
    title: "Statistics",
    type: "item",
    icon: <Icon.PieChart size={29} />,
    permissions: ["admin", "editor"],
    navLink: "/Statistics"
  }, {
    id: "accountSettings",
    title: "Settings",
    type: "item",
    icon: <Icon.Settings size={29} />,
    permissions: ["admin", "editor"],
    navLink: "/pages/my-settings"
  }, {
    id: "faq",
    title: "FAQ",
    type: "item",
    icon: <Icon.HelpCircle size={29} />,
    permissions: ["admin", "editor"],
    navLink: "/pages/faq"
  }, {
    id: "Feedback",
    title: "Feedback",
    type: "item",
    icon: <Icon.MessageSquare size={29} />,
    permissions: ["admin", "editor"],
    navLink: "/Feedback"
  }, {
    id: "Admin",
    title: "Admin",
    type: "item",
    icon: <Icon.Tool size={29} />,
    permissions: ["admin", "editor"],
    navLink: "/admin/Admin",
    parentOf: [ 
      "/admin/user/list",
      "/admin/user/edit",
      "/admin/user/view"
    ]
  }
]

export default navigationConfig
