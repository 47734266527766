import mock from "../mock";

export const searchResult = [
  {
    groupTitle: "Pages",
    searchLimit: 4,
    data: [
      {
        id: 1,
        target: "home",
        title: "Home",
        link: "/",
        icon: "Home",
      },
      {
        id: 75,
        target: "KnowledgeBase",
        title: "My Notes",
        link: "/pages/my-notes",
        icon: "AlignLeft",
      },
    ],
  },
];

mock.onGet("/api/main-search/data").reply(200, {
  searchResult,
});
